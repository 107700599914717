'use client'
import React, { useEffect } from 'react'
import { init } from '@fullstory/browser';
import { obtenerUsuario } from '@/app/scala/accounts';
import { sendLogWithURL } from './utils';

export default function Fullstory({fsDeclared}: any) {

    // const [isFsDeclared, setIsFsDeclared] = useState(false)

    // console.log('fsDeclared: ', fsDeclared);

    useEffect(() => {

        if(!fsDeclared){

        // console.log('FullStory Init')
        init({ orgId: 'o-1X3T73-na1' });

        // setIsFsDeclared(true)
        fsDeclared = true

        const fetchUser = async () => {
            const user = await obtenerUsuario()
            // console.log('user: ', user);
            if (user) {
                const FS = window.FS
                if (FS) {
                    // console.log('FS: ', FS);
                    // FS.identify('USER_ID', {
                    //     displayName: user.email,
                    //     email: user.email,
                    //     id: user.id
                    //     // Otras variables adicionales
                    // });
                    // FS.identify(user.id, {
                    //     displayName: user.email,
                    //     email: user.email,
                    //     id: user.id
                    //     // Otras variables adicionales
                    // });
                    FS('setIdentity', {
                        uid: user.id,
                        properties: {
                            displayName: user.email,
                            email: user.email
                        }
                    })
                    await sendLogWithURL(FS('getSession', { format: 'url' }))

                }
            }
        }

        fetchUser();

        }

    }, [fsDeclared])

    return null
}