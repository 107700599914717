'use client'

import Script from "next/script"
import { useEffect } from "react"
import { obtenerUsuario } from "@/app/scala/accounts"

const MicrosoftClarity = ({ mcDeclared }: any) => {

    useEffect(() => {


        if (!mcDeclared) {

            mcDeclared = true
            const fetchUser = async () => {
                const user = await obtenerUsuario()
                // console.log('user: ', user);
                if (user) {
                    const clarity = window.clarity
                    if (clarity) {
                        // console.log('clarity: ', clarity);
                        clarity("identify", user.id, null, null, user.email)
                    }
                }
            }

            fetchUser();

        }


    }, [])
    return (
        <Script
            id="microsoft-clarity-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_MICROSOFT_CLARITY}");
                `,
            }}
        />
    )
}

export default MicrosoftClarity